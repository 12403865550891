import React, { useEffect, useState } from "react";

import css from "../pesquisa.module.css";
import { Col, Form, Row } from "react-bootstrap";
import pesquisa from "../../../../../assets/icons/pesquisa/pesquisa.svg";
import { ReactComponent as Pesquisa } from "../../../../../assets/icons/pesquisa/pesquisar_big.svg";

import {
  createArrayRapida,
  atualizarPesquisaRapida,
  numerosPesquisaRapida,
  createUrl,
} from "../../../../helpers/tratarInfoCarros";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import RapidoSelect from "./RapidoSelect";
import Rapido from "./Rapido";
export default function FormPesquisaRapida({
  Array,
  setFilteredCarros,
  tipo,
  infoJson,
  avancada,
}) {
  const navigate = useNavigate();
  const [paramsBusca] = useSearchParams();
  const [query, updateQuery] = useState([]);
  const [querySelect, updateQuerySelect] = useState([]);
  const [newArray, setnewArray] = useState([]);
  const [NumeroResultados, setNumeroResultados] = useState(0);

  const handleSubmit = (e) => {
    let params = new URLSearchParams();
    // verifica se existe parametros na query
    e.preventDefault();

    let PesquisaMarcas = [];
    let PesquisaModelos = [];
    let PesquisaCombustivel = [];
    let PesquisaSegmento = [];
    let PesquisaLotacao = [];
    let PesquisaTransmissao = [];
    let PesquisaExtras = [];

    query.forEach((element) => {
      const arrElement = element.split(":");

      switch (arrElement[0]) {
        case "1":
          //marca
          PesquisaExtras = [...PesquisaExtras, arrElement[1]];

          break;
        case "2":
          //marca
          PesquisaMarcas = [...PesquisaMarcas, parseInt(arrElement[1])];

          break;
        case "3":
          //modelo
          PesquisaModelos = [...PesquisaModelos, parseInt(arrElement[1])];

          break;

        case "4":
          //combustivel
          PesquisaCombustivel = [
            ...PesquisaCombustivel,
            parseInt(arrElement[1]),
          ];
          break;
        case "5":
          //tipo
          PesquisaSegmento = [...PesquisaSegmento, parseInt(arrElement[1])];
          break;
        case "6":
          //tipo
          PesquisaLotacao = [...PesquisaLotacao, parseInt(arrElement[1])];
          break;

        case "7":
          //transmissao
          PesquisaTransmissao = [
            ...PesquisaTransmissao,
            parseInt(arrElement[1]),
          ];
          break;

        default:
          break;
      }
    });

    PesquisaCombustivel.length !== 0 &&
      params.append("Combustivel", PesquisaCombustivel);
    PesquisaSegmento.length !== 0 &&
      params.append("Segmento", PesquisaSegmento);
    PesquisaLotacao.length !== 0 && params.append("Lotacao", PesquisaLotacao);
    PesquisaTransmissao.length !== 0 &&
      params.append("Transmissao", PesquisaTransmissao);
    PesquisaExtras.length !== 0 && params.append("Extras", PesquisaExtras);

    const MarcasComModelo = Array.filter((carro) => {
      return PesquisaModelos.includes(carro.Modelo.Id);
    })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    PesquisaMarcas = [...PesquisaMarcas, ...MarcasComModelo];

    const path = `${infoJson.Layout.Carros.existe ? "/" : "/viaturas/usadas/"}${
      PesquisaMarcas.length !== 0
        ? `${createUrl(Array, "marca", PesquisaMarcas)}/`
        : ""
    }${
      PesquisaModelos.length !== 0
        ? `${createUrl(Array, "modelo", PesquisaModelos)}/`
        : ""
    }${params.toString() !== "" || query !== "" ? "?" : ""}${
      params.toString() !== "" ? `${params.toString()}` : ""
    }${params.toString() !== "" ? "&" : ""}${
      query.length !== 0
        ? `query=${query}${
            infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""
          }`
        : ""
    }`;

    navigate(`${path}`, { replace: true });
  };

  useEffect(() => {
    const queryUrl = paramsBusca.get("query");
    const ordenarUrl = paramsBusca.get("Order");
    let ordenar = "";

    if (ordenarUrl) {
      ordenar = parseInt(ordenarUrl);
    }

    if (queryUrl) {
      const querySelecionadas = queryUrl
        .toString()
        .split(",")
        .map((codigo) => {
          return {
            Codigo: codigo,
          };
        });

      const arr = newArray.filter((object1) => {
        return querySelecionadas.some((object2) => {
          return object1.Codigo === object2.Codigo;
        });
      });
      updateQuerySelect(arr);

      const arrR = queryUrl.toString().split(",");

      atualizarPesquisaRapida(
        arrR,
        ordenar,
        Array,
        setFilteredCarros,
        infoJson
      );
      setNumeroResultados(numerosPesquisaRapida(arrR, Array));
    } else {
      if (query.length !== 0) {
        setNumeroResultados(numerosPesquisaRapida(query, Array));
      } else {
        const number = Array.filter((item) => item.Vendido === false);
        setNumeroResultados(number.length);
      }
    }
  }, [paramsBusca, newArray, setFilteredCarros, Array, query, infoJson]);

  useEffect(() => {
    const item = createArrayRapida(Array);
    setnewArray(item);
  }, [Array]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        action={tipo}
        className={avancada ? "d-none" : "d-block"}
      >
        <Row className="align-items-center ">
          <Col
            xs={12}
            lg={3}
            className={`d-none  d-lg-block titulo-page ${css.titulo_nome}`}
          >
            Pesquise pelo nome
          </Col>
          <Col xs={9} sm={10} md={11} lg={8} className={`${css.barraPesquisa}`}>
            <Col xs={12} className={`d-block  d-lg-none ${css.titulo_nome}`}>
              Pesquise pelo nome
            </Col>

            {newArray.length !== 0 && querySelect.length !== 0 ? (
              <RapidoSelect
                Array={newArray}
                onChange={updateQuery}
                infoJson={infoJson}
                ArraySelect={querySelect}
              />
            ) : (
              <Rapido
                Array={newArray}
                onChange={updateQuery}
                infoJson={infoJson}
              />
            )}
          </Col>
          <Col xs={"auto"} sm={1} md={1}>
            <button className={` btnPesquisa ${css.btnPesquisa}`} type="submit">
              {infoJson.Layout.Carros.NumeroCarros ? (
                <>
                  <Pesquisa height={"35px"} width={"35px"} />
                  <span>{NumeroResultados}</span>
                </>
              ) : (
                <>
                  <img
                    height={"25px"}
                    width={"25px"}
                    src={pesquisa}
                    alt="pesquisar"
                  />
                </>
              )}
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
