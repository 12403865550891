import React from "react";
import { NavLink } from "react-router-dom";
import css from "./menuMobile.module.css";

import { ReactComponent as SvgOficina } from "../../../../assets/icons/servicos/svg_oficina.svg";
import { ReactComponent as SvgFinanciamento } from "../../../../assets/icons/servicos/svg_financiamento.svg";
import { ReactComponent as SvgLavagens } from "../../../../assets/icons/servicos/svg_lavagens.svg";
import { ReactComponent as SvgSeguros } from "../../../../assets/icons/servicos/svg_seguros.svg";
import { ReactComponent as SvgTestemunhos } from "../../../../assets/icons/servicos/svg_testemunhos.svg";
import { ReactComponent as SvgGaleria } from "../../../../assets/icons/servicos/svg_galeria.svg";
import { ReactComponent as SvgAlbumClientes } from "../../../../assets/icons/servicos/svg_album_clientes.svg";
import { ReactComponent as SvgNoticias } from "../../../../assets/icons/servicos/svg_noticias.svg";
import { ReactComponent as SvgCompramos } from "../../../../assets/icons/servicos/svg_compramos.svg";
import { ReactComponent as SvgConsultadoria } from "../../../../assets/icons/servicos/svg_consultadoria.svg";
import { ReactComponent as SvgAluguer } from "../../../../assets/icons/servicos/svg_aluguer.svg";
import { ReactComponent as SvgGarantia } from "../../../../assets/icons/servicos/svg_garantia.svg";

export default function MenuServicos({
  allinfoText,
  infoJson,
  nodeRef,
  onHide,
}) {
  return (
    <div ref={nodeRef} className={`${css.seconddiv} boxMenuMore`}>
      <ul className={`navbar-nav `}>
        {infoJson.Oficina.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Oficina && allinfoText.Oficina.Url !== ""
                  ? allinfoText.Oficina.Url
                  : infoJson.Oficina.url
              }
            >
              <SvgOficina />{" "}
              {allinfoText.Oficina && allinfoText.Oficina.Titulo !== ""
                ? allinfoText.Oficina.Titulo
                : infoJson.Oficina.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Financiamento.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Financiamento &&
                allinfoText.Financiamento.Url !== ""
                  ? allinfoText.Financiamento.Url
                  : infoJson.Financiamento.url
              }
            >
              <SvgFinanciamento />{" "}
              {allinfoText.Financiamento &&
              allinfoText.Financiamento.Titulo !== ""
                ? allinfoText.Financiamento.Titulo
                : infoJson.Financiamento.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Lavagens.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Lavagens && allinfoText.Lavagens.Url !== ""
                  ? allinfoText.Lavagens.Url
                  : infoJson.Lavagens.url
              }
            >
              <SvgLavagens />{" "}
              {allinfoText.Lavagens && allinfoText.Lavagens.Titulo !== ""
                ? allinfoText.Lavagens.Titulo
                : infoJson.Lavagens.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Seguros.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Seguros && allinfoText.Seguros.Url !== ""
                  ? allinfoText.Seguros.Url
                  : infoJson.Seguros.url
              }
            >
              <SvgSeguros />{" "}
              {allinfoText.Seguros && allinfoText.Seguros.Titulo !== ""
                ? allinfoText.Seguros.Titulo
                : infoJson.Seguros.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Testemunhos.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Testemunhos && allinfoText.Testemunhos.Url !== ""
                  ? allinfoText.Testemunhos.Url
                  : infoJson.Testemunhos.url
              }
            >
              <SvgTestemunhos />{" "}
              {allinfoText.Testemunhos && allinfoText.Testemunhos.Titulo !== ""
                ? allinfoText.Testemunhos.Titulo
                : infoJson.Testemunhos.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Galeria.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Galeria && allinfoText.Galeria.Url !== ""
                  ? allinfoText.Galeria.Url
                  : infoJson.Galeria.url
              }
            >
              <SvgGaleria />{" "}
              {allinfoText.Galeria && allinfoText.Galeria.Titulo !== ""
                ? allinfoText.Galeria.Titulo
                : infoJson.Galeria.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.AlbumClientes.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.AlbumClientes &&
                allinfoText.AlbumClientes.Url !== ""
                  ? allinfoText.AlbumClientes.Url
                  : infoJson.AlbumClientes.url
              }
            >
              <SvgAlbumClientes />{" "}
              {allinfoText.AlbumClientes &&
              allinfoText.AlbumClientes.Titulo !== ""
                ? allinfoText.AlbumClientes.Titulo
                : infoJson.AlbumClientes.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Noticias.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Noticias && allinfoText.Noticias.Url !== ""
                  ? allinfoText.Noticias.Url
                  : infoJson.Noticias.url
              }
            >
              <SvgNoticias />{" "}
              {allinfoText.Noticias && allinfoText.Noticias.Titulo !== ""
                ? allinfoText.Noticias.Titulo
                : infoJson.Noticias.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Compramos.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                infoJson.Compramos.easybuy.existe
                  ? infoJson.Compramos.easybuy.url
                  : allinfoText.Compramos && allinfoText.Compramos.Url !== ""
                  ? allinfoText.Compramos.Url
                  : infoJson.Compramos.url
              }
              target={infoJson.Compramos.easybuy.existe ? "_blank" : "_self"}
              rel="noopener noreferrer"
            >
              <SvgCompramos />{" "}
              {allinfoText.Compramos && allinfoText.Compramos.Titulo !== ""
                ? allinfoText.Compramos.Titulo
                : infoJson.Compramos.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Consultadoria.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Consultadoria &&
                allinfoText.Consultadoria.Url !== ""
                  ? allinfoText.Consultadoria.Url
                  : infoJson.Consultadoria.url
              }
            >
              <SvgConsultadoria />{" "}
              {allinfoText.Consultadoria &&
              allinfoText.Consultadoria.Titulo !== ""
                ? allinfoText.Consultadoria.Titulo
                : infoJson.Consultadoria.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Aluguer.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Aluguer && allinfoText.Aluguer.Url !== ""
                  ? allinfoText.Aluguer.Url
                  : infoJson.Aluguer.url
              }
            >
              <SvgAluguer />{" "}
              {allinfoText.Aluguer && allinfoText.Aluguer.Titulo !== ""
                ? allinfoText.Aluguer.Titulo
                : infoJson.Aluguer.titulo}
            </NavLink>
          </li>
        )}
        {infoJson.Garantia.existe && (
          <li>
            <NavLink
              onClick={onHide}
              className={(navData) =>
                navData.isActive ? "active boxMenuItem" : "boxMenuItem"
              }
              to={
                allinfoText.Garantia && allinfoText.Garantia.Url !== ""
                  ? allinfoText.Garantia.Url
                  : infoJson.Garantia.url
              }
            >
              <SvgGarantia />{" "}
              {allinfoText.Garantia && allinfoText.Garantia.Titulo !== ""
                ? allinfoText.Garantia.Titulo
                : infoJson.Garantia.titulo}
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
