import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import cssModal from "../../../modal/modalPartilhar.module.css";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { useCookies } from "react-cookie";
import * as apiEmpresa from "../../../../api/apiEmpresa";
import { Fragment } from "react";

export default function PopUp({ infoJson }) {
  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(["cookie-popUp"]);
  const [Banner, setBanner] = useState([]);

  useEffect(() => {
    if (cookies.popUp) {
      setShow(false);
    }
  }, [cookies.popUp]);

  useEffect(() => {
    const getBanner = async () => {
      const bannersDesktop = await apiEmpresa.getBanner(
        infoJson.Banner.PopUp,
        infoJson
      );

      setBanner(bannersDesktop);
    };
    getBanner();
  }, [infoJson]);

  const onDismiss = () => {
    setShow(false);
    if (infoJson.Stand.PopUp.Cookie) {
      setCookie("popUp", true, { maxAge: 86400 });
    }
  };

  return (
    <>
      {Banner.length !== 0 &&
        Banner.length !== undefined &&
        Banner.length !== null && (
          <>
            {!cookies.popUp && (
              <Modal show={show} size="lg" fullscreen={"md-down"}>
                <Modal.Body>
                  <div
                    onClick={() => onDismiss()}
                    className={` ${cssModal.close} close`}
                  >
                    <Fechar />
                  </div>

                  {Banner.map((banner) => {
                    const { Ficheiro, Target, Url, CodBanner } = banner;

                    return (
                      <React.Fragment key={CodBanner}>
                        {Url !== "" && Target !== "" ? (
                          <a href={Url} target={Target} rel="noreferrer">
                            <img
                              className="w-100 h-auto"
                              src={Ficheiro}
                              alt="pop up"
                            />
                          </a>
                        ) : (
                          <img
                            className="w-100 h-auto"
                            src={Ficheiro}
                            alt="pop up"
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
    </>
  );
}
