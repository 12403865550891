import React, { useState } from "react";
import css from "./menuMobile.module.css";
import { Offcanvas } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Listagem } from "../../../../assets/icons/menu/listagem.svg";
import { ReactComponent as Serv } from "../../../../assets/icons/menu/serv.svg";
import { ReactComponent as Pesq } from "../../../../assets/icons/menu/pesq.svg";
import { ReactComponent as Emp } from "../../../../assets/icons/menu/empresa.svg";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/contact.svg";
import { ReactComponent as Inicio } from "../../../../assets/icons/menu/inicio.svg";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import MenuViaturas from "./MenuViaturas";
import MenuServicos from "./MenuServicos";
import MenuFala from "./MenuFala";
import MenuRedes from "./MenuRedes";
import MenuFooter from "./MenuFooter";
import MenuTradutor from "./MenuTradutor";

const logo = "/assets/img/logo2.webp";

export default function MenuMobile({
  allinfo,
  show,
  onHide,
  allListaCarros,
  allListaComercias,
  allListaClassicos,
  allListaMotas,
  infoJson,
  allinfoText,
}) {
  const [myclass, setMyclass] = useState(false);
  const [myclass2, setMyclass2] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const nodeRef = React.useRef(null);
  const nodeRefS = React.useRef(null);

  const openClick = () => {
    myclass ? setMyclass(false) : setMyclass(true);
    myclass ? setDisabled(false) : setDisabled(true);
    setMyclass2(false);
  };
  const openClick2 = () => {
    myclass2 ? setMyclass2(false) : setMyclass2(true);
    myclass2 ? setDisabled(false) : setDisabled(true);
    setMyclass(false);
  };

  return (
    <>
      <Offcanvas show={show} onHide={onHide} placement="start">
        <Offcanvas.Header>
          <Offcanvas.Title className="m-auto">
            <Link to="/" onClick={onHide}>
              <div
                className={`nav navbar-nav navbar-mobile ${css.menu_mobile_stand}`}
              >
                <img src={logo} alt={infoJson.Stand.Nome} />
              </div>
            </Link>
          </Offcanvas.Title>

          <div onClick={() => onHide()} className={`${css.close}  close`}>
            <Fechar />
            Fechar
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="col p-3">
            <ul className={`${css.navbarMobile}  `}>
              <li>
                <Link
                  to="/"
                  onClick={onHide}
                  className={`menu ${disabled ? "menu disable" : ""}`}
                >
                  <div className={`${css.boxMenuBottom}  boxMenu`}>
                    <Inicio className=" p-0 mb-2 " height={`27px`} />
                    Início
                  </div>
                </Link>
              </li>
              {infoJson.Layout.MenuJunto ? (
                <li>
                  <Link
                    to={"/viaturas/usadas/"}
                    onClick={onHide}
                    className={`menu ${disabled ? "menu disable" : ""}`}
                  >
                    <div className={`${css.boxMenuBottom}  boxMenu`}>
                      <Listagem className=" p-0 mb-2 " height={`27px`} />
                      Viaturas
                    </div>
                  </Link>
                </li>
              ) : (
                <li>
                  <div
                    onClick={openClick2}
                    className={`menu ${myclass2 ? " active" : ""}  ${
                      disabled ? " disable" : ""
                    }`}
                  >
                    <div className={`${css.boxMenuBottom}   boxMenu`}>
                      <Listagem className=" p-0 mb-2 " height={`27px`} />
                      Viaturas
                    </div>
                  </div>
                </li>
              )}

              <li>
                <div
                  onClick={openClick}
                  className={`menu  ${myclass ? "active" : ""}  ${
                    disabled ? "disable" : ""
                  }`}
                >
                  <div className={`${css.boxMenuBottom} boxMenu`}>
                    <Serv className=" p-0 mb-2 " height={`27px`} />
                    Serviços
                  </div>
                </div>
              </li>

              <CSSTransition
                nodeRef={nodeRef}
                in={myclass2}
                unmountOnExit
                timeout={500}
                classNames={{
                  enter: `${css.opcoesEnter}`,
                  enterActive: `${css.opcoesEnterActive}`,
                  exit: `${css.opcoesExit}`,
                  exitActive: `${css.opcoesExitActive}`,
                }}
              >
                <MenuViaturas
                  nodeRef={nodeRef}
                  allListaCarros={allListaCarros}
                  allListaComercias={allListaComercias}
                  allListaClassicos={allListaClassicos}
                  allListaMotas={allListaMotas}
                  infoJson={infoJson}
                  onHide={onHide}
                />
              </CSSTransition>
              <CSSTransition
                nodeRef={nodeRefS}
                in={myclass}
                unmountOnExit
                timeout={300}
                classNames={{
                  enter: `${css.opcoesEnter}`,
                  enterActive: `${css.opcoesEnterActive}`,
                  exit: `${css.opcoesExit}`,
                  exitActive: `${css.opcoesExitActive}`,
                }}
              >
                <MenuServicos
                  nodeRef={nodeRefS}
                  allinfoText={allinfoText}
                  infoJson={infoJson}
                  onHide={onHide}
                />
              </CSSTransition>

              <li>
                <Link
                  to={`${
                    infoJson.Layout.Carros.existe ? "/" : "/viaturas/usadas/"
                  }`}
                  state={{ pesquisaAvancada: true, noative: true }}
                  onClick={onHide}
                  className={`menu ${disabled ? "menu disable" : ""}`}
                >
                  <div className={`${css.boxMenuBottom}  boxMenu`}>
                    <Pesq className=" p-0 mb-2 " height={`27px`} />
                    Pesquisar
                  </div>
                </Link>
              </li>

              {infoJson.Empresa.existe && (
                <li>
                  <NavLink
                    to={
                      allinfoText.Empresa && allinfoText.Empresa.Url !== ""
                        ? allinfoText.Empresa.Url
                        : infoJson.Empresa.url
                    }
                    state={{ noative: true }}
                    className={(navData) =>
                      navData.isActive
                        ? `active menu ${disabled ? "disable" : ""}`
                        : `menu ${disabled ? "disable" : ""}`
                    }
                    onClick={onHide}
                  >
                    <div className={`${css.boxMenuBottom}  boxMenu`}>
                      <Emp className=" p-0 mb-2 " height={`27px`} />
                      Empresa
                    </div>
                  </NavLink>
                </li>
              )}

              {infoJson.Contactos.existe && (
                <>
                  <li>
                    <NavLink
                      to={
                        allinfoText.Contactos &&
                        allinfoText.Contactos.Url !== ""
                          ? allinfoText.Contactos.Url
                          : infoJson.Contactos.url
                      }
                      state={{ noative: true }}
                      className={(navData) =>
                        navData.isActive
                          ? `active menu ${disabled ? "disable" : ""}`
                          : `menu ${disabled ? "disable" : ""}`
                      }
                      onClick={onHide}
                    >
                      <div className={`${css.boxMenuBottom} boxMenu`}>
                        <Contact className=" p-0 mb-2 " />
                        Contactos
                      </div>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div
            className={`${infoJson.Layout.ThemeDark ? css.boxBlack : css.boxt}`}
          ></div>
          <MenuFala allinfo={allinfo} infoJson={infoJson} />
          <MenuRedes allinfo={allinfo} />
          {infoJson.Layout.Tradutor && <MenuTradutor />}
          <MenuFooter
            allinfo={allinfo}
            infoJson={infoJson}
            allinfoText={allinfoText}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
