const global = require("../../config/config");

function formateDate(str) {
  try {
    const [dateComponents, timeComponents] = str.split(" ");
    // console.log(dateComponents); // 👉️ "22/04/2022"
    // console.log(timeComponents); // 👉️ "07:30:16"

    const [day, month, year] = dateComponents.split("/");
    const [hours, minutes, seconds] = timeComponents.split(":");

    const date = new Date(+year, month - 1, +day, +hours, +minutes, +seconds);
    return date;
    // console.log(date);
  } catch (error) {
    console.log("error", error);
  }
}

function formatNumber(value) {
  //O objeto Intl.NumberFormat é um construtor para objetos que habilita formatação de número sensível a linguagem.
  //?Ver Mais  https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

  try {
    //Formatar numeros que vem dos Km,...
    const formatter = Intl.NumberFormat("pt-pt");
    return formatter.format(value);
  } catch (error) {
    console.log("error", error);
  }
}
function formatNumberCurrency(value, casas) {
  //O objeto Intl.NumberFormat é um construtor para objetos que habilita formatação de número sensível a linguagem.
  //?Ver Mais  https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  try {
    //Formatar numeros que vem do Preço , Preço Promoção , Valor de Mensalidade,...
    const formatter = Intl.NumberFormat("fr-Fr", {
      style: "currency",
      currency: "EUR",
    });
    value = formatter.format(value);

    return casas === "2" ? value : value.replace(",00", "");
  } catch (error) {
    console.log("error", error);
  }
}
function isZero(param) {
  // Verifica se o valor vem igual a zero
  try {
    if (param !== "") {
      if (param === 0) {
        param = "";
      }
      if (param === "0") {
        param = "";
      }
    }
    return param;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarCombustivel(Combustivel) {
  // Verifica se a string tem a palavra string para "Híbrido" para encortar a palavra
  try {
    if (Combustivel !== "") {
      if (Combustivel.includes("Híbrido")) {
        Combustivel = "Híbrido";
      }
    }
    return Combustivel;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarLink(marca, modelo, codigo, Viatura) {
  //cria o link para as viaturas.
  //Limpa a Marca e Modelo , e colocar o codigo e o tipo de viatura(carros , motas e barcos)
  try {
    const aux_marca_clean = LimparToUrl(marca);
    const aux_modelo_clean = LimparToUrl(modelo);

    const location = `/${Viatura}/${aux_marca_clean}/${aux_modelo_clean}/${codigo}/`;
    return location;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarLinkFinanciamento(marca, modelo, codigo, Viatura) {
  //cria o link para as viaturas.
  //Limpa a Marca e Modelo , e colocar o codigo e o tipo de viatura(carros , motas e barcos)
  try {
    const aux_marca_clean = LimparToUrl(marca);
    const aux_modelo_clean = LimparToUrl(modelo);

    const location = `${Viatura}${codigo}/${aux_marca_clean}-${aux_modelo_clean}`;
    return location;
  } catch (error) {
    console.log("error", error);
  }
}

function LimparToUrl(text) {
  try {
    let textLimpo = "";
    // Lista de caracteres especiais que serão substituídos:
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";

    // Lista de caracteres que serão adicionados em relação aos anteriores:
    const to = "aaaaaeeeeeiiiiooooouuuunc------";
    if (text !== "") {
      // Converte o texto para caixa baixa:
      textLimpo = text.toLocaleLowerCase();
      // Remove qualquer caractere em branco do final do texto:
      textLimpo = textLimpo.replace(/^\s+|\s+$/g, "");

      // Substitui todos os caracteres especiais:
      for (let i = 0, l = from.length; i < l; i++) {
        textLimpo = textLimpo.replace(
          new RegExp(from.charAt(i), "g"),
          to.charAt(i)
        );
      }
    }
    // Remove qualquer caractere inválido que possa ter sobrado no texto:
    textLimpo = textLimpo.replace(/[^a-z0-9 -]/g, "");
    // Substitui os espaços em branco por hífen:
    textLimpo = textLimpo.replace(/\s+/g, "-");
    return textLimpo;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarAnoMes(ano, mes) {
  try {
    //Traforma o mes e ano

    let mesTxt;
    switch (mes) {
      case ("1", 1):
        mesTxt = "Jan.";
        break;
      case ("2", 2):
        mesTxt = "Fev.";
        break;
      case ("3", 3):
        mesTxt = "Mar.";
        break;
      case ("4", 4):
        mesTxt = "Abr.";
        break;
      case ("5", 5):
        mesTxt = "Mai.";
        break;
      case ("6", 6):
        mesTxt = "Jun.";
        break;
      case ("7", 7):
        mesTxt = "Jul.";
        break;
      case ("8", 8):
        mesTxt = "Ago.";
        break;
      case ("9", 9):
        mesTxt = "Set.";
        break;
      case ("10", 10):
        mesTxt = "Out.";
        break;
      case ("11", 11):
        mesTxt = "Nov.";
        break;
      case ("12", 12):
        mesTxt = "Dez.";
        break;
      default:
        mesTxt = "";
        break;
    }
    const AnoMes = `${mesTxt} ${ano}`;

    return AnoMes;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarKm(km, existe) {
  try {
    // trata a informação dos km
    let kmFormat = isZero(km);
    if (kmFormat !== "") {
      kmFormat = formatNumber(km);
      if (existe) {
        return `${kmFormat} `;
      } else {
        return `${kmFormat} km`;
      }
    } else {
      return "";
    }
  } catch (error) {
    console.log("error", error);
  }
}

function tratarPreco(preco) {
  try {
    // trata a informação do Preco
    let precoText = "";

    if (preco !== "" && preco !== 0) {
      precoText = formatNumberCurrency(preco, 0);
    }
    return precoText;
  } catch (error) {
    console.log("error", error);
  }
}

function SplitValue(value) {
  try {
    // split do vaor
    let myArray;
    if (value !== "" && value !== 0) {
      if (value.includes(" ")) {
        myArray = value.split(" ");
      } else {
        myArray = ["", value];
      }
    } else {
      myArray = ["", ""];
    }

    return myArray;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarPrecoPromo(precoPromo) {
  try {
    // trata a informação do Preco Promoçao
    precoPromo = isZero(precoPromo);
    let precoPromoText = "";

    if (precoPromo !== "") {
      precoPromoText = formatNumberCurrency(precoPromo, 0);
    }
    return precoPromoText;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarValorMensalidade(valorMensalidade) {
  try {
    // trata a informação do valor Mensalidade
    valorMensalidade = isZero(valorMensalidade);
    if (
      typeof valorMensalidade === "string" ||
      valorMensalidade instanceof String
    ) {
      valorMensalidade = valorMensalidade.replace(",", ".");
    }

    let valorMensalidadeText = "";
    if (valorMensalidade !== "") {
      valorMensalidadeText = formatNumberCurrency(valorMensalidade, 2);
    }

    return valorMensalidadeText;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarMotorizacao(versaoAlternativa, motorizacao) {
  try {
    // trata a informação do Motorizacao
    if (versaoAlternativa !== "" && versaoAlternativa !== undefined) {
      return versaoAlternativa;
    }
    return motorizacao;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarValorFinanciado(Preco, PrecoPromo, ValorEntrada) {
  try {
    // trata a informação do Valor Financiado
    let precoInit;
    PrecoPromo !== "" && PrecoPromo !== 0
      ? (precoInit = PrecoPromo)
      : Preco !== "" && Preco !== 0
      ? (precoInit = Preco)
      : (precoInit = 999999);

    return formatNumberCurrency(precoInit - ValorEntrada, 0);
  } catch (error) {
    console.log("error", error);
  }
}

function tratarCilindrada(cilindrada) {
  try {
    // trata a informação do valor Cilindrada
    let valorCilindrada = "";
    if (cilindrada !== "") {
      valorCilindrada = formatNumber(cilindrada, 0);
      valorCilindrada = valorCilindrada + " Cc";
    }
    return valorCilindrada;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarPotencia(potencia) {
  try {
    // trata a informação do valor Potencia
    let valorPotencia = "";
    if (potencia !== "") {
      valorPotencia = formatNumber(potencia, 0);
      valorPotencia = valorPotencia + " Cv";
    }
    return valorPotencia;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarConsumos(ConsumoUrbano, ConsumoExtra, ConsumoMisto) {
  try {
    // trata a informação do valor dos Consumos

    let valorConsumos = "";
    if (ConsumoMisto !== "") {
      valorConsumos = valorConsumos + ConsumoMisto + "L/100Km (Combinado) <br>";
    }
    if (ConsumoUrbano !== "") {
      valorConsumos = valorConsumos + ConsumoUrbano + "L/100Km (Urbano)  <br>";
    }
    if (ConsumoExtra !== "") {
      valorConsumos =
        valorConsumos + ConsumoExtra + "L/100Km (Extra Urbano) <br>";
    }
    return valorConsumos;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarNacional(nacional) {
  try {
    // trata a informação do valor dos Nacionalidade

    let textNacional = "";
    if (nacional === 1) {
      textNacional = "Nacional";
    } else if (nacional === 2) {
      textNacional = "Importado";
    } else {
      textNacional = "";
    }
    return textNacional;
  } catch (error) {
    console.log("error", error);
  }
}
function tratar2Chave(SegundaChave) {
  try {
    // trata a informação da Segunda Chave
    let textSegundaChave = "";
    if (SegundaChave === true) {
      textSegundaChave = "Sim";
    }
    return textSegundaChave;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarClassePortagem(ClassePortagem) {
  try {
    // trata a informação da Clase de Portagem
    let textClassePortagem = "";
    if (ClassePortagem !== "" && ClassePortagem !== "-1") {
      textClassePortagem = "Classe " + ClassePortagem;
    }
    return textClassePortagem;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarLivroRevisoes(ExtrasSoltos) {
  try {
    // trata a informação da Livro de revisões completo

    let textLivroRevisoes = "";
    if (ExtrasSoltos.includes("Livro de revisões completo")) {
      textLivroRevisoes = "Sim";
    }

    return textLivroRevisoes;
  } catch (error) {
    console.log("error", error);
  }
}

function tratarUrl(url, url2) {
  try {
    // trata a o url do video do youtube

    let linkUrl = "";
    if (url !== "") {
      linkUrl = url.replace("https://www.youtube.com/watch?v=", "");
      linkUrl = linkUrl.replace("https://youtu.be/", "");
      linkUrl = "https://www.youtube.com/embed/" + linkUrl;
    }
    if (url2 !== "") {
      linkUrl = url2.replace("https://www.youtube.com/watch?v=", "");
      linkUrl = linkUrl.replace("https://youtu.be/", "");
      linkUrl = "https://www.youtube.com/embed/" + linkUrl;
    }

    return linkUrl;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarRegistoManutencao(Ano, Mes) {
  try {
    // trata a informação da Livro de revisões completo

    // Verifica quanto dias tem a viatura
    let tempo = "";
    tempo = new Date().getFullYear() - Ano;
    tempo > 3 ? (tempo = tratarAnoMes(Ano, Mes)) : (tempo = tempo + " Anos");
    //se tiver mais que 3 anos coloca o mes e o ano

    return tempo;
  } catch (error) {
    console.log("error", error);
  }
}
function tratarDataManutencao(Data) {
  try {
    //Trata de data de Intervenção

    let textData;
    let mesTxt;
    let dataExtenso = Data.split(" ");
    dataExtenso = dataExtenso[0].split("/");

    switch (dataExtenso[1]) {
      case "01":
        mesTxt = "Janeiro";
        break;
      case "02":
        mesTxt = "Fevereiro";
        break;
      case "03":
        mesTxt = "Março";
        break;
      case "04":
        mesTxt = "Abril";
        break;
      case "05":
        mesTxt = "Maio";
        break;
      case "06":
        mesTxt = "Junho";
        break;
      case "07":
        mesTxt = "Julho";
        break;
      case "08":
        mesTxt = "Agosto";
        break;
      case "09":
        mesTxt = "Setembro";
        break;
      case "10":
        mesTxt = "Outubro";
        break;
      case "11":
        mesTxt = "Novembro";
        break;
      case "12":
        mesTxt = "Dezembro";
        break;
      default:
        mesTxt = "";
        break;
    }

    textData = dataExtenso[0] + " " + mesTxt + " de " + dataExtenso[2];
    return textData;
  } catch (error) {
    console.log("error", error);
  }
}
function trataManutencaoMostraAno(Data, array, index) {
  try {
    //inica as variaveis
    let mostrarAno = 0;
    let dataExtensoAnterior;
    let anoAgora;

    // cria um array da data
    let dataExtenso = Data.split(" ");
    dataExtenso = dataExtenso[0].split("/");

    if (index === 0) {
      anoAgora = new Date().getFullYear();
    } else {
      const indexAnterior = index - 1;
      dataExtensoAnterior = array[indexAnterior].DataIntervencao.split(" ");
      dataExtensoAnterior = dataExtensoAnterior[0].split("/");
      anoAgora = parseInt(dataExtensoAnterior[2]);
    }

    const anoIntervençao = parseInt(dataExtenso[2]);

    anoIntervençao !== anoAgora && (mostrarAno = 1);

    return [mostrarAno, dataExtenso[2]];
  } catch (error) {
    console.log("error", error);
  }
}

const atualizarPesquisa = (
  lotacao,
  marcas,
  modelo,
  combustivel,
  segmento,
  transmissao,
  extras,
  anoInicial,
  anoFinal,
  precoInicial,
  precoFinal,
  kmInicial,
  kmFinal,
  potenciaInicial,
  mensalidadeInicial,
  mensalidadeFinal,
  ordenar,
  ponto,
  array,
  set,
  infoJson
) => {
  try {
    const MarcasComModelo = array
      .filter((carro) => {
        return modelo.includes(carro.Modelo.Id);
      })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    const MarcasSemMarcasComModelo = marcas.filter((carro) => {
      return !MarcasComModelo.includes(carro);
    });

    const pesquisa = array.filter((carro) => {
      let result = true;

      if (marcas && marcas.length > 0 && modelo && modelo.length > 0) {
        result =
          result &&
          (MarcasSemMarcasComModelo.includes(carro.Marca.Id) ||
            modelo.includes(carro.Modelo.Id));
      } else {
        if (marcas && marcas.length > 0) {
          result = result && marcas.includes(carro.Marca.Id);
        }
      }

      if (combustivel && combustivel.length > 0) {
        result = result && combustivel.includes(carro.Combustivel.Id);
      }
      if (extras && extras.length > 0) {
        result =
          result &&
          extras.every((extra) =>
            carro.ExtrasSoltos.toLowerCase().includes(extra.toLowerCase())
          );
      }
      if (ponto) {
        result = result && carro.CodPontoVenda === parseInt(ponto);
      }
      if (transmissao && transmissao.length > 0) {
        if (transmissao.includes(1)) {
          result =
            result &&
            global.CaixaManual.includes(carro.Transmissao.Id) &&
            carro.Vendido === false;
        }
        if (transmissao.includes(2)) {
          result =
            result &&
            global.CaixaAutomatica.includes(carro.Transmissao.Id) &&
            carro.Vendido === false;
        }
      }

      if (segmento && segmento.length > 0) {
        result =
          result && segmento.includes(carro.Tipo.Id) && carro.Vendido === false;
      }
      if (anoInicial && anoInicial > 0) {
        result = result && carro.Ano >= anoInicial && carro.Vendido === false;
      }
      if (anoFinal && anoFinal > 0) {
        result = result && carro.Ano <= anoFinal && carro.Vendido === false;
      }
      if (precoInicial && precoInicial > 0) {
        result = result && parseInt(carro.Preco) >= precoInicial;
      }
      if (precoFinal && precoFinal > 0) {
        result = result && parseInt(carro.Preco) <= precoFinal;
      }

      if (mensalidadeInicial) {
        result =
          result &&
          parseFloat(carro.ValorMensalidade.replace(",", ".")) >=
            parseFloat(mensalidadeInicial.replace(",", ".")) &&
          carro.ValorMensalidade !== "";
      }

      if (lotacao && lotacao.length > 0) {
        result =
          result &&
          lotacao.includes(carro.Lugares.Id) &&
          carro.Vendido === false;
      }

      if (mensalidadeFinal) {
        result =
          result &&
          parseFloat(carro.ValorMensalidade.replace(",", ".")) <=
            parseFloat(mensalidadeFinal.replace(",", ".")) &&
          carro.ValorMensalidade !== "";
      }
      if (kmInicial && kmInicial > 0) {
        result =
          result && parseInt(carro.Km) >= kmInicial && carro.Vendido === false;
      }
      if (kmFinal && kmFinal > 0) {
        result =
          result && parseInt(carro.Km) <= kmFinal && carro.Vendido === false;
      }
      if (potenciaInicial && potenciaInicial > 0) {
        result = result && carro.Potencia >= potenciaInicial;
      }

      return result;
    });

    set(
      SelectOrdenar(infoJson.Layout.Carros.ordenar.recentes, ordenar, pesquisa)
    );
  } catch (error) {
    console.log("error", error);
  }
};

const SelectOrdenar = (recentes, item, array) => {
  try {
    switch (item) {
      case 1:
        //sortMarcaAZ
        return array
          .sort(function (a, b) {
            if (a.Marca.Nome < b.Marca.Nome) {
              return -1;
            }
            if (a.Marca.Nome > b.Marca.Nome) {
              return 1;
            }
            return 0;
          })
          .map((item) => {
            return item;
          });

      case 2:
        //sortMarcaZA
        return array
          .sort(function (a, b) {
            if (a.Marca.Nome > b.Marca.Nome) {
              return -1;
            }
            if (a.Marca.Nome < b.Marca.Nome) {
              return 1;
            }
            return 0;
          })
          .map((item) => {
            return item;
          });

      case 3:
        // sortAnoMaior
        return array
          .sort((a, b) => b.Ano - a.Ano)
          .map((item) => {
            return item;
          });

      case 4:
        // sortAnoMenor
        return array
          .sort((a, b) => a.Ano - b.Ano)
          .map((item) => {
            return item;
          });

      case 5:
        // sortPrecoMaior
        return array
          .sort((a, b) => a.Preco - b.Preco)
          .sort((a, b) => a.Vendido - b.Vendido)
          .map((item) => {
            return item;
          });
      case 6:
        // sortPrecoMenor
        return array
          .sort((a, b) => b.Preco - a.Preco)
          .sort((a, b) => a.Vendido - b.Vendido)
          .map((item) => {
            return item;
          });
      default:
        if (recentes) {
          return array
            .sort(
              (a, b) =>
                formateDate(b.UltimaAlteracao).getTime() -
                formateDate(a.UltimaAlteracao).getTime()
            )
            .sort((a, b) => a.Vendido - b.Vendido)
            .map((item) => {
              return item;
            });
        } else {
          return array
            .sort(function (a, b) {
              if (a.Marca.Nome < b.Marca.Nome) {
                return -1;
              }
              if (a.Marca.Nome > b.Marca.Nome) {
                return 1;
              }
              return 0;
            })
            .sort((a, b) => a.Vendido - b.Vendido)
            .map((item) => {
              return item;
            });
        }
    }
  } catch (error) {
    console.log("error", error);
  }
};

const NumeroAtualizarPesquisa = (
  marcas,
  modelo,
  combustivel,
  segmento,
  transmissao,
  extras,
  anoInicial,
  anoFinal,
  precoInicial,
  precoFinal,
  kmInicial,
  kmFinal,
  potenciaInicial,
  lotacao,
  array
) => {
  try {
    const MarcasComModelo = array
      .filter((carro) => {
        return modelo.includes(carro.Modelo.Id);
      })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    const MarcasSemMarcasComModelo = marcas.filter((carro) => {
      return !MarcasComModelo.includes(carro);
    });

    const pesquisa = array.filter((carro) => {
      let result = true;
      result = result && carro.Vendido === false;
      if (marcas && marcas.length > 0 && modelo && modelo.length > 0) {
        result =
          result &&
          (MarcasSemMarcasComModelo.includes(carro.Marca.Id) ||
            modelo.includes(carro.Modelo.Id));
      } else {
        if (marcas && marcas.length > 0) {
          result = result && marcas.includes(carro.Marca.Id);
        }
      }

      if (combustivel && combustivel.length > 0) {
        result = result && combustivel.includes(carro.Combustivel.Id);
      }

      if (lotacao && lotacao.length > 0) {
        result = result && lotacao.includes(carro.Lugares.Id);
      }

      if (extras && extras.length > 0) {
        result =
          result &&
          extras.every((extra) =>
            carro.ExtrasSoltos.toLowerCase().includes(extra.toLowerCase())
          );
      }

      if (transmissao && transmissao.length > 0) {
        if (transmissao.includes(1)) {
          result = result && global.CaixaManual.includes(carro.Transmissao.Id);
        }
        if (transmissao.includes(2)) {
          result =
            result && global.CaixaAutomatica.includes(carro.Transmissao.Id);
        }
      }

      if (segmento && segmento.length > 0) {
        result = result && segmento.includes(carro.Tipo.Id);
      }
      if (anoInicial && anoInicial > 0) {
        result = result && carro.Ano >= anoInicial;
      }
      if (anoFinal && anoFinal > 0) {
        result = result && carro.Ano <= anoFinal;
      }
      if (precoInicial && precoInicial > 0) {
        result = result && parseInt(carro.Preco) >= precoInicial;
      }
      if (precoFinal && precoFinal > 0) {
        result = result && parseInt(carro.Preco) <= precoFinal;
      }

      if (kmInicial && kmInicial > 0) {
        result = result && parseInt(carro.Km) >= kmInicial;
      }
      if (kmFinal && kmFinal > 0) {
        result = result && parseInt(carro.Km) <= kmFinal;
      }
      if (potenciaInicial && potenciaInicial > 0) {
        result = result && carro.Potencia >= potenciaInicial;
      }

      return result;
    });

    return pesquisa.length;
  } catch (error) {
    console.log("error", error);
  }
};

const sortMarcaAZ = (array, set) => {
  try {
    const marcaAZ = array
      .sort(function (a, b) {
        if (a.Marca.Nome < b.Marca.Nome) {
          return -1;
        }
        if (a.Marca.Nome > b.Marca.Nome) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        return item;
      });

    set(marcaAZ);
  } catch (error) {
    console.log("error", error);
  }
};

const createUrl = (array, tipo, url) => {
  try {
    let item = "";
    if (tipo === "marca") {
      item = array
        .sort(function (a, b) {
          if (a.Marca.Nome < b.Marca.Nome) {
            return -1;
          }
          if (a.Marca.Nome > b.Marca.Nome) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, carro) => {
          const accumulator = [...accum];
          if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
            accumulator.push(carro);
          }
          return accumulator;
        }, [])
        .filter((item) => {
          return url.includes(item.Marca.Id);
        })
        .map((item) => item.Marca.Nome.trim())
        .toString()
        .replaceAll(",", "--");
    } else {
      if (tipo === "modelo") {
        item = array
          .sort(function (a, b) {
            if (a.Modelo.Nome < b.Modelo.Nome) {
              return -1;
            }
            if (a.Modelo.Nome > b.Modelo.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (
              !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
            ) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .filter((item) => {
            return url.includes(item.Modelo.Id);
          })
          .map((item) => item.Modelo.Nome.trim())
          .toString()
          .replaceAll(",", "--");
      }
    }

    return LimparToUrl(item);
  } catch (error) {
    console.log("error", error);
  }
};

const createArrayRapida = (Array) => {
  try {
    const allListaPorVender = Array.filter((carro) => {
      return carro.Vendido === false;
    });
    const ExtrasSoltos = allListaPorVender.map((extras) => {
      return extras.ExtrasSoltos;
    });
    let arrExtras = [
      "AC",
      "Ar Condicionado",
      "Ajuda ao parqueamento",
      "Alarme",
      "Android Auto",
      "Apoio de Braço",
      "Apple CarPlay",
      "Audi Smartphone interface",
      "Bancos Desportivos",
      "Bancos Dianteiros Aquecidos",
      "Bancos Dianteiros c/ Memória",
      "Bancos Dianteiros c/ Regulação Eléctrica",
      "Bancos Dianteiros com Apoio Lombar",
      "Bancos em Alcântara",
      "Bancos Ortopédicos",
      "Bancos Traseiros Aquecidos",
      "Bancos Traseiros c/ Config. Individual",
      "Bixenón",
      "Barras de Tejadilho",
      "Bluetooth",
      "Camara de Frente",
      "Câmara de Marcha",
      "Camara Laterais",
      "Capota Eléctrica",
      "Cruise Control",
      "Dianteiros Led",
      "Ecrã Consola Central",
      "Ecrã Encosto de Cabeça",
      "Ecrã Táctil ou Touch Screen",
      "Entrada AUX",
      "Estacionamento Automático",
      "Estofos em Pele",
      "Faróis Diurnos",
      "Full Led",
      "Função Luzes Coming & Leaving Home",
      "Ecrã Consola Central",
      "Gancho de Reboque",
      "GPS",
      "Head Up Display",
      "ISOFIX",
      "Jantes 14",
      "Jantes 15",
      "Jantes 16",
      "Jantes 17",
      "Jantes 18",
      "Jantes 19",
      "Jantes 20",
      "Jantes 21",
      "Jantes de Liga Leve",
      "Livro de revisões completo",
      "Luzes Traseiras LED",
      "Mirror Link",
      "MMI",
      "Porta Bagageira Automática",
      "Portas Laterais Automáticas",
      "Sensores Estacionamento",
      "Start and Stop",
      "Suspensão Desportiva",
      "Tecnologia Led",
      "Tecto De Abrir Eléctrico",
      "Tecto Panorâmico",
      "Travão de Mão Eléctrico",
      "Vidros Escurecidos",
      "Volante Desportivo",
      "Volante Multifunções",
      "Xenón",
    ];

    const extras = ExtrasSoltos.toString()
      .split(",")
      .map((extras) => {
        return extras.trim();
      })
      .filter((item) => item !== "" && arrExtras.includes(item))
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, extra) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === extra)) {
          accumulator.push(extra);
        }
        return accumulator;
      }, [])
      .map((extras) => {
        return { Codigo: `1:${extras.trim()}`, Nome: extras.trim() };
      });

    const marca = allListaPorVender
      .map(({ Marca }) => {
        return {
          Marca,
        };
      })
      .sort(function (a, b) {
        if (a.Marca.Nome < b.Marca.Nome) {
          return -1;
        }
        if (a.Marca.Nome > b.Marca.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, [])
      .map((marca) => {
        return { Codigo: `2:${marca.Marca.Id}`, Nome: marca.Marca.Nome };
      });

    const modelo = allListaPorVender
      .sort(function (a, b) {
        if (a.Modelo.Nome < b.Modelo.Nome) {
          return -1;
        }
        if (a.Modelo.Nome > b.Modelo.Nome) {
          return 1;
        }
        return 0;
      })
      .map(({ Modelo, Marca }) => {
        return {
          Modelo,
          Marca,
        };
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, [])
      .map((modelo) => {
        return {
          Codigo: `3:${modelo.Modelo.Id}`,
          Nome: `${modelo.Marca.Nome} ${modelo.Modelo.Nome}`,
        };
      });

    const combustivel = allListaPorVender
      .map(({ Combustivel }) => {
        return {
          Combustivel,
        };
      })
      .sort(function (a, b) {
        if (a.Combustivel.Nome < b.Combustivel.Nome) {
          return -1;
        }
        if (a.Combustivel.Nome > b.Combustivel.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (
          !accumulator.some(
            (item) => item.Combustivel.Id === carro.Combustivel.Id
          )
        ) {
          accumulator.push(carro);
        }
        return accumulator;
      }, [])
      .map((combustivel) => {
        return {
          Codigo: `4:${combustivel.Combustivel.Id}`,
          Nome: combustivel.Combustivel.Nome,
        };
      });

    const tipo = allListaPorVender
      .map(({ Tipo }) => {
        return {
          Tipo,
        };
      })
      .sort(function (a, b) {
        if (a.Tipo.Nome < b.Tipo.Nome) {
          return -1;
        }
        if (a.Tipo.Nome > b.Tipo.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Tipo.Id === carro.Tipo.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, [])
      .map((tipo) => {
        return {
          Codigo: `5:${tipo.Tipo.Id}`,
          Nome: tipo.Tipo.Nome,
        };
      });

    const lugares = allListaPorVender
      .map(({ Lugares }) => {
        return {
          Lugares,
        };
      })
      .sort(function (a, b) {
        if (a.Lugares.Nome < b.Lugares.Nome) {
          return -1;
        }
        if (a.Lugares.Nome > b.Lugares.Nome) {
          return 1;
        }
        return 0;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item.Lugares.Id === carro.Lugares.Id)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, [])
      .map((lugares) => {
        return {
          Codigo: `6:${lugares.Lugares.Id}`,
          Nome: lugares.Lugares.Nome,
        };
      });
    const transmissao = [
      { Codigo: `7:1`, Nome: "Automática" },
      { Codigo: `7:2`, Nome: "Manual" },
    ];

    return [
      ...marca,
      ...modelo,
      ...combustivel,
      ...tipo,
      ...lugares,
      ...transmissao,
      ...extras,
    ];
  } catch (error) {
    console.log("error", error);
  }
};

const atualizarPesquisaRapida = (query, ordenar, array, set, infoJson) => {
  try {
    let marcas = [];
    let modelo = [];
    let combustivel = [];
    let segmento = [];
    let transmissao = [];
    let extras = [];
    let lugares = [];

    query.forEach((element) => {
      const arrElement = element.split(":");

      switch (arrElement[0]) {
        case "1":
          //extras
          extras = [...extras, arrElement[1]];
          break;
        case "2":
          //marca
          marcas = [...marcas, parseInt(arrElement[1])];
          break;
        case "3":
          //modelo
          modelo = [...modelo, parseInt(arrElement[1])];
          break;
        case "4":
          //combustivel
          combustivel = [...combustivel, parseInt(arrElement[1])];
          break;
        case "5":
          //tipo
          segmento = [...segmento, parseInt(arrElement[1])];
          break;
        case "6":
          //lugares
          lugares = [...lugares, parseInt(arrElement[1])];
          break;
        case "7":
          //transmissao
          transmissao = [...transmissao, parseInt(arrElement[1])];
          break;

        default:
          break;
      }
    });

    const MarcasComModelo = array
      .filter((carro) => {
        return modelo.includes(carro.Modelo.Id);
      })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    const MarcasSemMarcasComModelo = marcas.filter((carro) => {
      return !MarcasComModelo.includes(carro);
    });

    const pesquisa = array.filter((carro) => {
      let result = true;

      if (modelo && modelo.length > 0) {
        result =
          result &&
          (MarcasSemMarcasComModelo.includes(carro.Marca.Id) ||
            modelo.includes(carro.Modelo.Id));
      } else {
        if (marcas && marcas.length > 0) {
          result = result && marcas.includes(carro.Marca.Id);
        }
      }

      if (combustivel && combustivel.length > 0) {
        result = result && combustivel.includes(carro.Combustivel.Id);
      }

      if (lugares && lugares.length > 0) {
        result = result && lugares.includes(carro.Lugares.Id);
      }

      if (extras && extras.length > 0) {
        result =
          result &&
          extras.every((extra) =>
            carro.ExtrasSoltos.toLowerCase().includes(extra.toLowerCase())
          );
      }

      if (transmissao && transmissao.length > 0) {
        if (transmissao.includes(1)) {
          result = result && global.CaixaManual.includes(carro.Transmissao.Id);
        }
        if (transmissao.includes(2)) {
          result =
            result && global.CaixaAutomatica.includes(carro.Transmissao.Id);
        }
      }

      if (segmento && segmento.length > 0) {
        result = result && segmento.includes(carro.Tipo.Id);
      }

      return result;
    });

    set(
      SelectOrdenar(infoJson.Layout.Carros.ordenar.recentes, ordenar, pesquisa)
    );
  } catch (error) {
    console.log("error", error);
  }
};

const numerosPesquisaRapida = (query, array) => {
  try {
    let marcas = [];
    let modelo = [];
    let combustivel = [];
    let segmento = [];
    let transmissao = [];
    let extras = [];
    let lugares = [];

    query.forEach((element) => {
      const arrElement = element.split(":");

      switch (arrElement[0]) {
        case "1":
          //extras
          extras = [...extras, arrElement[1]];
          break;
        case "2":
          //marca
          marcas = [...marcas, parseInt(arrElement[1])];
          break;
        case "3":
          //modelo
          modelo = [...modelo, parseInt(arrElement[1])];
          break;
        case "4":
          //combustivel
          combustivel = [...combustivel, parseInt(arrElement[1])];
          break;
        case "5":
          //tipo
          segmento = [...segmento, parseInt(arrElement[1])];
          break;
        case "6":
          //lugares
          lugares = [...lugares, parseInt(arrElement[1])];
          break;
        case "7":
          //transmissao
          transmissao = [...transmissao, parseInt(arrElement[1])];
          break;

        default:
          break;
      }
    });

    const MarcasComModelo = array
      .filter((carro) => {
        return modelo.includes(carro.Modelo.Id);
      })
      .map((carro) => {
        return carro.Marca.Id;
      })
      .reduce((accum, carro) => {
        const accumulator = [...accum];
        if (!accumulator.some((item) => item === carro)) {
          accumulator.push(carro);
        }
        return accumulator;
      }, []);

    const MarcasSemMarcasComModelo = marcas.filter((carro) => {
      return !MarcasComModelo.includes(carro);
    });

    const pesquisa = array.filter((carro) => {
      let result = true;
      result = result && carro.Vendido === false;

      if (modelo && modelo.length > 0) {
        result =
          result &&
          (MarcasSemMarcasComModelo.includes(carro.Marca.Id) ||
            modelo.includes(carro.Modelo.Id));
      } else {
        if (marcas && marcas.length > 0) {
          result = result && marcas.includes(carro.Marca.Id);
        }
      }

      if (combustivel && combustivel.length > 0) {
        result = result && combustivel.includes(carro.Combustivel.Id);
      }

      if (lugares && lugares.length > 0) {
        result = result && lugares.includes(carro.Lugares.Id);
      }

      if (extras && extras.length > 0) {
        result =
          result &&
          extras.every((extra) =>
            carro.ExtrasSoltos.toLowerCase().includes(extra.toLowerCase())
          );
      }

      if (transmissao && transmissao.length > 0) {
        if (transmissao.includes(1)) {
          result = result && global.CaixaManual.includes(carro.Transmissao.Id);
        }
        if (transmissao.includes(2)) {
          result =
            result && global.CaixaAutomatica.includes(carro.Transmissao.Id);
        }
      }

      if (segmento && segmento.length > 0) {
        result = result && segmento.includes(carro.Tipo.Id);
      }

      return result;
    });

    return pesquisa.length;
  } catch (error) {
    console.log("error", error);
  }
};

export {
  tratarCombustivel,
  tratarLink,
  tratarAnoMes,
  tratarKm,
  tratarPreco,
  tratarPrecoPromo,
  tratarValorMensalidade,
  tratarCilindrada,
  tratarPotencia,
  tratarConsumos,
  tratarNacional,
  tratar2Chave,
  tratarClassePortagem,
  tratarLivroRevisoes,
  tratarUrl,
  tratarRegistoManutencao,
  tratarDataManutencao,
  trataManutencaoMostraAno,
  LimparToUrl,
  atualizarPesquisa,
  NumeroAtualizarPesquisa,
  SplitValue,
  tratarMotorizacao,
  tratarLinkFinanciamento,
  sortMarcaAZ,
  tratarValorFinanciado,
  createUrl,
  SelectOrdenar,
  createArrayRapida,
  atualizarPesquisaRapida,
  numerosPesquisaRapida,
  formateDate,
};
