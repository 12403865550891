import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Marca from "../PesquisaAvançada/Marca";
import Modelos from "../PesquisaAvançada/Modelos";
import Combustivel from "../PesquisaAvançada/Combustivel";
import MarcaSelect from "../PesquisaAvançada/MarcaSelect";
import ModelosSelect from "../PesquisaAvançada/ModelosSelect";
import CombustivelSelect from "../PesquisaAvançada/CombustivelSelect";
import css from "../pesquisa.module.css";
import pesquisa from "../../../../../assets/icons/pesquisa/pesquisa.svg";
import { ReactComponent as Pesquisa } from "../../../../../assets/icons/pesquisa/pesquisar_big.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery ";
import {
  createUrl,
  LimparToUrl,
  NumeroAtualizarPesquisa,
} from "../../../../helpers/tratarInfoCarros";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as apiDados from "../../../../../api/apiDados";

export default function FormPesquisaReduzida({
  Array,
  tipo,
  infoJson,
  avancada,
}) {
  const ismobile = useMediaQuery("(max-width: 767px)");
  let { marca, modelo } = useParams();
  const navigate = useNavigate();
  const [paramsBusca] = useSearchParams();

  const [NumeroResultados, setNumeroResultados] = useState([0]);
  const [modelos, setModelos] = useState([]);
  const [PesquisaMarcas, setPesquisaMarcas] = useState([]);
  const [PesquisaMarcasSelect, setPesquisaMarcasSelect] = useState([]);
  const [PesquisaModelos, setPesquisaModelos] = useState([]);
  const [PesquisaModelosSelect, setPesquisaModelosSelect] = useState([]);
  const [PesquisaCombustivel, setPesquisaCombustivel] = useState([]);
  const [PesquisaCombustivelSelect, setPesquisaCombustivelSelect] = useState(
    []
  );

  useEffect(() => {
    const number = Array.filter((item) => item.Vendido === false);
    setNumeroResultados(number.length);
  }, [Array]);

  const onChangeMarcas = (marcas, modelos) => {
    setPesquisaMarcas(marcas);
    setModelos(modelos);
  };

  const handleSubmit = (e) => {
    let params = new URLSearchParams();
    // verifica se existe parametros na query

    PesquisaCombustivel.length !== 0 &&
      params.append("Combustivel", PesquisaCombustivel);
    e.preventDefault();

    const path = `${
      infoJson.Layout.Carros.existe ? "/usadas/" : "/viaturas/usadas/"
    }${
      PesquisaMarcas.length !== 0
        ? `${createUrl(Array, "marca", PesquisaMarcas)}/`
        : ""
    }${
      PesquisaModelos.length !== 0
        ? `${createUrl(Array, "modelo", PesquisaModelos)}/`
        : ""
    }${params.toString() !== "" ? `?${params.toString()}` : ""}`;

    navigate(
      `${path}${infoJson.Layout.Carros.existe ? "#listagem_viaturas" : ""}`,
      { replace: true, state: { goToListagem: true } }
    );

    if (
      PesquisaMarcas.length !== 0 ||
      PesquisaModelos.length !== 0 ||
      PesquisaCombustivel.length !== 0
    ) {
      apiDados.InsertDados(
        PesquisaMarcas,
        PesquisaModelos,
        PesquisaCombustivel,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        3,
        infoJson
      );
    }
  };

  useEffect(() => {
    const valor = NumeroAtualizarPesquisa(
      PesquisaMarcas,
      PesquisaModelos,
      PesquisaCombustivel,
      [],
      [],
      [],
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      Array
    );
    setNumeroResultados(valor);
  }, [PesquisaMarcas, PesquisaModelos, PesquisaCombustivel, Array]);

  const rowSwift = (value) => {
    switch (value) {
      case 2:
        return 2;
      case 3:
        if (ismobile) {
          return 2;
        } else {
          return;
        }
      case 4:
        return 2;
      case 5:
        return;

      case 6:
        return 2;

      default:
        return 6;
    }
  };

  const colSwiftPesquisa = (value) => {
    switch (value) {
      case 2:
        return;
      case 3:
        if (ismobile) {
          return;
        } else {
          return "auto";
        }
      case 4:
        return;
      case 5:
        return "auto";
      case 6:
        return;
      default:
        return 6;
    }
  };

  const clasBtnSwift = (value) => {
    switch (value) {
      case 2:
        return `${css.btnPesquisaLarge}`;
      case 3:
        if (ismobile) {
          return `${css.btnPesquisaLarge}`;
        } else {
          return ``;
        }
      case 4:
        return `${css.btnPesquisaLarge} ${css.btnPesquisaPosition}`;
      case 5:
        return ``;
      case 6:
        return `${css.btnPesquisaLarge}`;

      default:
        return `${css.btnPesquisaLarge}`;
    }
  };

  const clasText = (value) => {
    switch (value) {
      case 2:
        return `Pesquisa`;
      case 3:
        if (ismobile) {
          return `Pesquisa`;
        } else {
          return ``;
        }
      case 4:
        return `Pesquisa`;
      case 5:
        return ``;
      case 6:
        return `Pesquisa`;
      default:
        return ``;
    }
  };

  const clasComponent = (value, int) => {
    switch (value) {
      case 2:
        return (
          <>
            <img
              height={"18px"}
              width={"18px"}
              src={pesquisa}
              alt="pesquisar"
            />{" "}
            Pesquisa {int}
          </>
        );
      case 3:
        if (ismobile) {
          return (
            <>
              <img
                height={"18px"}
                width={"18px"}
                src={pesquisa}
                alt="pesquisar"
              />{" "}
              Pesquisa {int}
            </>
          );
        } else {
          return (
            <>
              {" "}
              <Pesquisa height={"35px"} width={"35px"} />
              <span>{int}</span>
            </>
          );
        }
      case 4:
        return (
          <>
            <img
              height={"18px"}
              width={"18px"}
              src={pesquisa}
              alt="pesquisar"
            />{" "}
            Pesquisa {int}
          </>
        );
      case 5:
        return (
          <>
            {" "}
            <Pesquisa height={"35px"} width={"35px"} />
            <span>{int}</span>
          </>
        );
      case 6:
        return (
          <>
            <img
              height={"18px"}
              width={"18px"}
              src={pesquisa}
              alt="pesquisar"
            />{" "}
            Pesquisa {int}
          </>
        );
      default:
        return ``;
    }
  };

  useEffect(() => {
    const CombustivelUrl = paramsBusca.get("Combustivel");
    let filterMarca = [];
    let filterModelo = [];

    if (marca) {
      // cria array
      let arrMarca;
      if (marca.includes("--")) {
        arrMarca = marca.split("--");
      } else {
        arrMarca = [marca];
      }

      arrMarca.forEach((itemMarca) => {
        const filterItemMarca = Array.filter(
          (carro) => itemMarca === LimparToUrl(carro.Marca.Nome.toLowerCase())
        )
          .sort(function (a, b) {
            if (a.Marca.Nome < b.Marca.Nome) {
              return -1;
            }
            if (a.Marca.Nome > b.Marca.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (!accumulator.some((item) => item.Marca.Id === carro.Marca.Id)) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .map((item) => item.Marca.Id);
        filterMarca = [...filterMarca, ...filterItemMarca];
      });

      setPesquisaMarcas(filterMarca);

      const MarcasSelecionadas = filterMarca
        .toString()
        .split(",")
        .map((codigo) => {
          return {
            Marca: { Id: parseInt(codigo) },
          };
        });

      setPesquisaMarcasSelect(MarcasSelecionadas);

      const Modelos = Array.filter((carro) => {
        return filterMarca.includes(carro.Marca.Id) && carro.Vendido === false;
      })
        .map(({ Modelo }) => {
          return {
            Modelo: { Id: Modelo.Id, Nome: Modelo.Nome },
          };
        })
        .sort(function (a, b) {
          if (a.Modelo.Nome < b.Modelo.Nome) {
            return -1;
          }
          if (a.Modelo.Nome > b.Modelo.Nome) {
            return 1;
          }
          return 0;
        })
        .reduce((accum, carro) => {
          const accumulator = [...accum];
          if (!accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)) {
            accumulator.push(carro);
          }
          return accumulator;
        }, []);
      setModelos(Modelos);
    }

    if (modelo) {
      // cria array
      let arrModelo;
      if (modelo.includes("--")) {
        arrModelo = modelo.split("--");
      } else {
        arrModelo = [modelo];
      }
      arrModelo.forEach((itemModelo) => {
        const filterItemModelo = Array.filter(
          (carro) => itemModelo === LimparToUrl(carro.Modelo.Nome.toLowerCase())
        )
          .sort(function (a, b) {
            if (a.Modelo.Nome < b.Modelo.Nome) {
              return -1;
            }
            if (a.Modelo.Nome > b.Modelo.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (
              !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
            ) {
              accumulator.push(carro);
            }
            return accumulator;
          }, [])
          .map((item) => item.Modelo.Id);

        filterModelo = [...filterModelo, ...filterItemModelo];
      });

      setPesquisaModelos(filterModelo);

      const ModelosSelecionadas = filterModelo
        .toString()
        .split(",")
        .map((codigo) => {
          return {
            Modelo: { Id: parseInt(codigo) },
          };
        });

      setPesquisaModelosSelect(ModelosSelecionadas);
    }

    if (CombustivelUrl) {
      const combustivelSelecionadas = CombustivelUrl.toString()
        .split(",")
        .map((codigo) => {
          return {
            Combustivel: { Id: parseInt(codigo) },
          };
        });

      setPesquisaCombustivelSelect(combustivelSelecionadas);
    } else {
      setPesquisaCombustivelSelect([]);
    }
  }, [Array, marca, modelo, paramsBusca]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        action={tipo}
        className={avancada ? "d-none" : "d-block"}
      >
        <Row
          xs={rowSwift(infoJson.Pesquisa.rapida.tipo)}
          className={`align-items-center justify-content-center ${css.rowPesquisaReduzinda}`}
        >
          <Col>
            <Col
              xs={12}
              className={`${css.boxShadowPares} mb-3 boxShadowPares `}
            >
              <label>Marca </label>
              {Array.length !== 0 && PesquisaMarcasSelect.length !== 0 ? (
                <MarcaSelect
                  Array={Array}
                  onChange={onChangeMarcas}
                  ArraySelect={PesquisaMarcasSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Marca
                  Array={Array}
                  onChange={onChangeMarcas}
                  infoJson={infoJson}
                  ArraySelect={PesquisaMarcasSelect}
                />
              )}
            </Col>
          </Col>
          <Col>
            <Col
              xs={12}
              className={`${css.boxShadowPares} mb-3  boxShadowPares `}
            >
              <label>Modelo </label>
              {modelos.length === 0 && PesquisaModelosSelect.length === 0 ? (
                <div className={`${css.btnPesquisaModelo} selectSvg`}>
                  Selecionar
                </div>
              ) : modelos.length !== 0 && PesquisaModelosSelect.length !== 0 ? (
                <ModelosSelect
                  Array={modelos}
                  onChange={setPesquisaModelos}
                  ArraySelect={PesquisaModelosSelect}
                  infoJson={infoJson}
                />
              ) : (
                <Modelos
                  Array={modelos}
                  onChange={setPesquisaModelos}
                  infoJson={infoJson}
                />
              )}
            </Col>
          </Col>

          {infoJson.Pesquisa.rapida.tipo !== 5 &&
            infoJson.Pesquisa.rapida.tipo !== 4 && (
              <Col>
                <Col
                  xs={12}
                  className={`${css.boxShadowPares} mb-3 boxShadowPares `}
                >
                  <label>Combustivel </label>
                  {Array.length !== 0 &&
                  PesquisaCombustivelSelect.length !== 0 ? (
                    <CombustivelSelect
                      Array={Array}
                      onChange={setPesquisaCombustivel}
                      ArraySelect={PesquisaCombustivelSelect}
                      infoJson={infoJson}
                    />
                  ) : (
                    <Combustivel
                      Array={Array}
                      onChange={setPesquisaCombustivel}
                      infoJson={infoJson}
                    />
                  )}
                </Col>
              </Col>
            )}

          <Col
            className="position-relative"
            xs={colSwiftPesquisa(infoJson.Pesquisa.rapida.tipo)}
          >
            <button
              className={` btnPesquisa mb-3 ${css.btnPesquisa} ${clasBtnSwift(
                infoJson.Pesquisa.rapida.tipo
              )} `}
              type="submit"
            >
              {infoJson.Layout.Carros.NumeroCarros ? (
                <>
                  {clasComponent(
                    infoJson.Pesquisa.rapida.tipo,
                    NumeroResultados
                  )}
                </>
              ) : (
                <>
                  <img
                    height={"25px"}
                    width={"25px"}
                    src={pesquisa}
                    alt="pesquisar"
                  />{" "}
                  {clasText(infoJson.Pesquisa.rapida.tipo)}
                </>
              )}
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
